import * as Yup from "yup";
import i18n from "i18next";

export const validationSchema = (showInfo, isFlexible, amountMin, amountMax) => {
    return Yup.object().shape({
        amount: isFlexible
            ? Yup.number().nullable().integer(i18n.t('wrongFormat'))
                .typeError(i18n.t('wrongFormat'))
                .min(amountMin, `${i18n.t('flexibleAmount1')} ${amountMin}-${amountMax} ${i18n.t('flexibleAmount2')}`)
                .max(amountMax, `${i18n.t('flexibleAmount1')} ${amountMin}-${amountMax} ${i18n.t('flexibleAmount2')}`)
                .required(i18n.t('required'))
            : Yup.number()
                .typeError(i18n.t('wrongFormat'))
                .notRequired(),
        customerName: !showInfo ? Yup.string().notRequired() : Yup
            .string()
            .test('len', i18n.t('wrongFormat'), item => !item?.length || item?.length < 256)
            .required(i18n.t('required')),
        customerMobile: !showInfo ? Yup.string().notRequired() : Yup.string()
            .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, i18n.t('wrongFormat'))
            .required(i18n.t('required')),
        customerEmail: !showInfo ? Yup.string().notRequired() : Yup.string().email(i18n.t('wrongFormat')).required(i18n.t('required')),
    });
}