export const errorValues = {
    status: false,
    image: '',
    text: ''
}
export const payment = {
    requestId: '',
    amount: '',
    quantity: 0,
    anonymous: true,
    customerName: null,
    customerEmail: null,
    customerMobile: null,
    paymentSystem: '',
    language: ''
};

export const linkDataValues = {
    allowAnonymous: false,
    requestId: '',
    requestName: '',
    isFlexible: true,
    amount: 0,
    amountMin: 0,
    amountMax: 0,
    quantity: 0,
    availableCount: 0,
    currency: 'AMD',
    partnerId: '',
    paymentSystems: [''],
    language: ''
};

export const statementValues = {
    requestType: '',
    orderId: 0,
    amount: '',
    dateTime: '',
    description: '',
    transactionDetails: {
        additionalProp1: '',
        additionalProp2: '',
        additionalProp3: ''
    }
};

export const currencies = {
    'AMD': '֏',
    'USD': '$',
    'RUR': '₽',
    'EUR': '€'
};

export const paymentOptions = [
    { id: 'card', label: 'Card', value: 'VPOS' },
    { id: 'mypay', label: 'MyPay', value: 'MYAMERIAPAY' },
    { id: 'telcell', label: 'Telcell', value: 'TELCELL' },
    { id: 'idram', label: 'Idram', value: 'IDRAM' },
    { id: 'fastshift', label: 'Fastshift', value: 'FASTSHIFT' }
];

export const languages = [
    { id: 'hy', label: 'Armenian' },
    { id: 'en', label: 'English' },
    { id: 'ru', label: 'Russian' },
    { id: 'fr', label: 'French' }
];
