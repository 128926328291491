import React from "react";
import './style.css'

const baseURL = `${process.env.PUBLIC_URL}/assets/`;

const Error = ({text, image}) => {
    return (
        <div className="error-container">
            <img src={`${baseURL}images/${image}.svg`} alt="Error" />
            <div className="disclaimer">{text}</div>
        </div>
    );
};

export default Error;
