import React from "react";
import './style.css'

const baseURL = `${process.env.PUBLIC_URL}/assets/`;

const Footer = ({logo = ''}) => {
    return (
        <div className="footer">
            {logo?.length ?
                <img src={`${baseURL}images/logo.svg`} alt="PayLink Logo" /> :
                <img src={`${baseURL}icons/dinno.svg`} alt="Dinno Logo" />
            }
            {' | '}
            <div className="license">Powered by Ameriabank CJSC</div>
        </div>
    );
};

export default Footer;
