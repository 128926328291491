import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Loader from 'react-dots-loader';
import 'react-dots-loader/index.css';
import Footer from "../../components/footer";
import Header from "../../components/header";
import { getStatement } from "../../helpers/methods";
import {errorValues, statementValues} from "../../enums";
import { useSearchParams } from "react-router-dom";
import Error from "../../components/error";

const Statement = () => {
    const [statementData, setStatementData] = useState(statementValues);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(errorValues)
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const language = searchParams.get('lang');

    useEffect(() => {
        i18n.changeLanguage(language);
        getStatement(setStatementData, setLoading, setError, id);
    }, [i18n, language, id]);

    return (
        <div className="statement wrapper-container">
            <Header />
            <div className={`form-container ${error.status ? 'error' : ''}`}>
                {isLoading ?
                    <div className="overlay">
                        <Loader color="#FFFFFF" size={16} />
                    </div> : error.status ?
                        <Error {...error}/>
                        :
                    <>
                        <div className="title">{t('statement')}</div>
                        <div className="secondary-text">{statementData.description}</div>

                        <div className="details">
                            <div>{t('purpose')}</div>
                            <div>{statementData.requestType}</div>
                        </div>

                        <div className="details">
                            <div>{t('amount')}</div>
                            <div>{statementData.amount}</div>
                        </div>

                        <div className="details">
                            <div>{t('date')}</div>
                            <div>{statementData.dateTime}</div>
                        </div>

                        <div className="details">
                            <div>{t('orderID')}</div>
                            <div>{statementData.orderId}</div>
                        </div>

                        {Object.entries(statementData?.transactionDetails).map(([key, value]) => (
                            <div className="details" key={key}>
                                <div>{t(key)}</div>
                                <div>{value}</div>
                            </div>
                        ))}
                    </>
                }
            </div>
            <Footer />
        </div>
    );
};

export default Statement;
