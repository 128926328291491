import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import en from './locales/en.json';
import hy from './locales/hy.json';
import ru from './locales/ru.json';
import fr from './locales/fr.json';

// Initialize i18next
i18n
    .use(initReactI18next) // Passes i18n instance to react-i18next.
    .init({
        resources: {
            en: {
                translation: en
            },
            hy: {
                translation: hy
            },
            ru: {
                translation: ru
            },
            fr: {
                translation: fr
            }
        },
        lng: 'hy', // Default language
        fallbackLng: 'hy', // Fallback language if the key doesn't exist
        interpolation: {
            escapeValue: false // React already escapes values to prevent XSS
        }
    });

export default i18n;
