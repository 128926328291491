import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PaymentForm from './pages/paymentForm';
import Statement from './pages/statement';
function App() {
  return (
      <Router>
        <Routes>
          <Route path="statement" element={<Statement />} />
          <Route path="/" element={<PaymentForm />} />
        </Routes>
      </Router>
  );
}

export default App;
