import React, {useEffect, useRef, useState} from "react";
import {languages} from "../../enums";
import './style.css'
import {useTranslation} from "react-i18next";

const baseURL = `${process.env.PUBLIC_URL}/assets/`;
const Language = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const {i18n} = useTranslation();
    const selectedLanguage = i18n.language;
    const ref = useRef(null);
    const handleSelectLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setIsDropdownOpen(false);
    };

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsDropdownOpen(false); // Close the div when clicking outside
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='language-container'>
            <div className='selectedLanguage' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                <img src={`${baseURL}icons/flags/${selectedLanguage}.svg`} alt={selectedLanguage}/>
                <span className='anchor'>{' '}</span>
            </div>

            {isDropdownOpen && (
                <div ref={ref} className='dropdown'>
                    {languages.map((language) => (
                        language.id !== selectedLanguage && <div
                            key={language.id}
                            onClick={() => handleSelectLanguage(language.id)}
                        >
                            <img src={`${baseURL}icons/flags/${language.id}.svg`} alt={language.id}/>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
export default Language